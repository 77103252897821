.link {
    text-decoration: none;
    color: inherit;
}

.link:hover, .linl:visited, .link:focus, .link:active {
    text-decoration: none;
    color: inherit;
}

a{
    text-decoration: none !important;
    color: inherit !important;
}

a:active, a:hover, a:visited, a:focus{
    text-decoration: none !;
    color: inherit !important;
}