@import url('https://fonts.googleapis.com/css?family=Poppins:regular,bold,italic&subset=latin,latin-ext');

.icons{
    cursor: pointer;
}

.main_logo{
    margin-bottom: 20px;
    max-width: 100%;
    height: auto;
}

.main_card{
    background-color: rgb(255, 255, 255);
    text-align: center;
    justify-content: center;
}

.profile{
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    margin-top: -75px;
}

.save_btn{
    background-color: black;
    color: #ffff;
    width: 90%;
    padding: 14px;
    border-radius: 40px;
    font-size: 18px;
    vertical-align: baseline;
    border: none;
}

.social_icons{
    cursor: pointer;
    background: black;
    padding: 15px;
    border-radius: 50%;
    color: #ffff;
    min-width: 30px;
}

.min_width_pics{
    min-width: 90px;
}

.footer{
    cursor: pointer;
}

.row {
    --bs-gutter-x: 0 !important;   
}




.loader{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.top-bar {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.image_logo_top {
    position: 'relative';
}